@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@900&family=Titillium+Web&display=swap");

.page-section {
  display: block;
  overflow: hidden;
}

.section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 200px 0;
}

.section-hero {
  padding-top: 100px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.section-biscuit {
  background: #fbfbfd;
  padding-top: 100px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.section-biscuit-layout {
  flex-direction: column;
}

.section-work {
  background: #000;
  color: #fff;
  padding-top: 100px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.section-cool {
  background: #fbfbfd;
  padding-top: 100px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  flex-flow: row wrap;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
}

.profile-image {
  width: 550px;
  height: 275px;
  clip-path: circle();
  overflow: hidden;
  object-fit: cover;
  object-position: 0 38%;
}

.profile-heading {
  margin-bottom: 0;
  font-size: 64px;
}

.profile-caption {
  color: #86868b;
  margin: 0;
}

.profile-caption-invert {
  color: rgb(204, 201, 201);
  margin: 0;
}

.profile-intro {
  font-size: 24px;
  width: 80%;
  margin: 50px auto;
}

.biscuit-image-row {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: auto;
  gap: 2px;
}

.biscuit-image {
  width: 650px;
  height: 750px;
  border-radius: 5px;
  overflow: hidden;
  object-fit: cover;
  object-position: 0 55%;
}

/* npx component */
button {
  background-color: grey;
  border-radius: 4px;
  margin-top: 18px;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

button:hover {
  background-color: rgb(92, 91, 91);
}

#npx-package {
  align-self: left;
  padding: 10px;
  color: black;
  text-align: left;
  max-width: fit-content;
  font-size: 20px;
  margin-bottom: 0;
}

#info-disclaimer {
  font-family: "Titillium Web", sans-serif;
  font-size: 15px;
  color: #d3d3d3;
  line-height: 50%;
  animation-name: pop-up;
  animation-duration: 10s;
}
/* end of npx component */

@media only screen and (max-width: 2000px) and (min-width: 1601px) {
  .biscuit-image {
    width: 450px;
    height: 550px;
  }

}

@media only screen and (max-width: 1600px) and (min-width: 1101px) {
  .biscuit-image {
    width: 350px;
    height: 450px;
  }

}

@media only screen and (max-width: 1100px) and (min-width: 751px) {
  .biscuit-image {
    width: 350px;
    height: 450px;
  }

  .biscuit-image-ipad {
    display: none;
  }

}

@media only screen and (max-width: 749px) {
  .profile-image {
    width: 375px;
  }

  .profile-heading {
    font-size: 52px;
  }

  .profile-intro {
    margin: 25px auto 50px auto;
  }

  .biscuit-image {
    width: 350px;
    height: 450px;
  }

  .biscuit-image-mobile {
    display: none;
  }
}
